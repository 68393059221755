<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Adicionar Opção</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'opcoes' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Opções
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
     <div class="col-12">
         <div class="card">
             <div class="card-body">
                 <div class="row">
                    <div class="col-12 mb-base">
                      <label>
                        <span class="mr-1 text-danger">*</span>
                        Name
                      </label>
                      <b-form-group>
                        <b-form-input
                          placeholder="Nome da Opção"
                          for="text"
                          v-model="form.name"
                          :class="{ 'is-invalid': form && $v.form.name.$error }"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                      <label><span class="mr-1 text-danger">*</span>Tipo</label>
                      <multiselect
                        v-model="form.type"
                        :options="options_type"
                        class="helo"
                        placeholder="Tipo do campo"
                        select-label="Selecionar"
                        deselect-label="Remover"
                        :allow-empty="false"
                        :class="{ 'is-invalid': form && $v.form.type.$error }"
                      ></multiselect>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                      <label>Obrigatório?</label>
                      <multiselect
                        v-model="form.required"
                        :options="options_required"
                        class="helo"
                        placeholder="Seleção obrigatório?"
                        select-label="Selecionar"
                        deselect-label="Remover"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                    <div class="col-12">
                      <hr/>
                    </div>
                    <div class="col-12 mb-2">
                      <h6>Opções</h6>
                    </div>
                    <div class="col-sm-12 col-md-8 mb-2">
                        <b-form-group>
                          <b-form-input
                            placeholder="Opção"
                            for="text"
                            v-model="options.name"
                            :class="{ 'is-invalid': error }"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-2 mb-2">
                        <b-form-group>
                          <b-form-input
                            placeholder="Opção"
                            for="color"
                            type="color"
                            v-model="options.color"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-2 mb-2">
                      <b-button variant="info" class="w-100" @click.prevent="addOption()">
                        Adicionar
                      </b-button>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(op, index) in optionsaray" :key="index">
                        <div class="col-sm-12 col-md-8">
                            <b-form-group>
                              <b-form-input
                                placeholder="Opção"
                                for="text"
                                v-model="op.name"
                              ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <b-form-group>
                              <b-form-input
                                placeholder="Opção"
                                for="color"
                                type="color"
                                v-model="op.color"
                              ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-2">
                          <b-button variant="danger" class="w-100" @click.prevent="removeOption(op)">
                            remover
                          </b-button>
                        </div>
                      </div>
                    </div>
                    

                    <div class="col-12 mt-3 text-right">
                      <b-button variant="success" @click.prevent="salvarDados()">
                        <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                        Salvar
                      </b-button>
                      <b-button
                        variant="danger"
                        class="ml-2"
                        @click.prevent="$router.push({ name: 'opcoes' })"
                      >
                        <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                        Cancelar
                      </b-button>
                    </div>
                  </div>
             </div>
         </div>
     </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      options_type: [
        "Selecionar",
        "Radio",
        "Checkbox",
        "Texto"
      ],
      options_required: [
        "Sim",
        "Não",
      ],
      options: {},
      error: false,
      form: {
          name: null,
          type: null,
          required: 'Sim',
          options: [],
      },
      optionsaray: []
    }
  },
  validations: {
    form: {
      name: { required },
      type: { required },
    },
  },
  mounted() {
    if (this.edit != null) {
      this.form = this.edit
      this.optionsaray = JSON.parse(this.edit.options)
    }
  },
  watch:{
    options:function(){
      if(this.options.name && this.options.name != null && this.options.name != ''){
        this.error = false
      }
    }
  },
  methods: {
    ...mapActions('option', ['ActionOptionAll','ActionOptionCreate','ActionOptionUpdate']),
    addOption(){
      if(this.options.name && this.options.name != null && this.options.name != ''){
        this.optionsaray.push(this.options);
       this.options = {}
      }else{
        this.error = true
      }
    },
    removeOption(value){
       this.optionsaray.splice(this.optionsaray.indexOf(value), 1);
    },
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitOption()
      }
    },
    async submitOption() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

        this.form.options = JSON.stringify(this.optionsaray)
        
        if (this.edit != null) {
          var dados = {
            data: this.form,
            id: this.form.id,
          }
          await this.ActionOptionUpdate(dados)
        } else {
          await this.ActionOptionCreate(this.form)
        }
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'opcoes' })
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: e.body.message ? e.body.message : 'Não foi possível realizar a ação!',
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>